import HttpRequest from "@/components/httpRequest"
import { formatDateToISO, roundFloat2, roundFloat6 } from "@/components/utils"
import useAppInfo, { showErrorMessage, showSuccessMessage, showWarningMessage } from "@/modules/app/use-app-info"
import useBankAccountListUnit from "@/modules/bank-account/use-bank-account-list-unit"
import { makeCheckReadyForSending } from "@/modules/check/check-unit/use-check-unit"
import useCompanyListUnit from "@/modules/company/company-list-unit/use-company-list-unit"
import { fetchExchangeRates } from "@/modules/currency/exchange-option-picker"
import useCurrencyListUnit from "@/modules/currency/use-currency-list-unit"
import useInvoiceDetailUnit from "@/modules/invoice/invoice-detail/use-invoice-detail-unit"
import { invoiceModuleVars } from "@/modules/invoice/invoice-module-vars"
import { INVOICE_TYPES } from "@/modules/invoice/invoice-params"
import usePaymentUnit from "@/modules/invoice/payment/use-payment-unit"
import useProductListUnit from "@/modules/product/product-list-unit/use-product-list-unit"
import useVaultAccountListUnit from "@/modules/vault-account/use-vault-account-list-unit"
import uniqueId from "lodash/uniqueId"
import isEmpty from "lodash/isEmpty"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { useInvoicePickerUnit } from "@/modules/invoice/return/use-invoice-picker-unit"
import { forEach } from "lodash"

const REQUIRED_MESSAGE = "Zorunlu alan"

const findErrors = (invoice) => {
  const errors = {}
  if (!invoice.companyId) errors.companyId = REQUIRED_MESSAGE
  if (!invoice.invoiceDate) errors.invoiceDate = REQUIRED_MESSAGE
  return errors
}

const initialState = {
  reloadKey: 0,
  pageStatus: undefined,
  editorMode: undefined,
  vars: {},
  varsSecond: {},
  invoice: {},
  invoicesSubjectToReturn: undefined,
  returns: undefined,
  errors: {},
  validationWasMade: false,
  autoFocusOn: false,
}


const useInvoiceUnit = create(immer((set, get) =>
({
  ...initialState,
  reload() {
    set((state) => {
      state.reloadKey += 1
    })
  },

  setEditorMode(value) {
    set((state) => {
      state.editorMode = value
    })
  },

  setPageStatus(pageStatus) {
    set((state) => {
      state.pageStatus = pageStatus
    })
  },

  toggleAutoFocus(trueOrFalse) {
    set((state) => {
      state.autoFocusOn = trueOrFalse
    })
  },

  changeInvoice(values) {
    set((state) => {
      state.invoice = { ...state.invoice, ...values }
    })

    if ("invoiceDate" in values) {
      const loadExchangeRates = get().loadExchangeRates
      const invoiceId = get().invoice.invoiceId

      if (values.invoiceDate) {
        if (!invoiceId) {
          set((state) => {
            state.invoice.invoiceExchangeRateDate = state.invoice.invoiceExchangeRateDate &&
              state.invoice.invoiceCurrencyId !== 1 ? values.invoiceDate : undefined

            state.invoice.effectiveExchangeRateDate = state.invoice.invoiceExchangeRateDate
              && state.invoice.invoiceCurrencyId !== state.invoice.effectiveCurrencyId && state.invoice.effectiveCurrencyId
              ? values.invoiceDate
              : undefined
          })
        }

        loadExchangeRates()
      }
    }

    const { validate, validationWasMade } = get()
    if (validationWasMade) validate()

  },

  validate() {
    const errors = findErrors(get().invoice)
    set((state) => {
      state.errors = errors
      state.validationWasMade = true
    })
    return isEmpty(errors)
  },

  loadRequirements() {
    return Promise.all([
      useCompanyListUnit.getState().loadCompanies(),
      useCurrencyListUnit.getState().loadCurrencies(),
      useProductListUnit.getState().loadProducts(),
      useBankAccountListUnit.getState().loadBankAccounts(),
      useVaultAccountListUnit.getState().loadVaultAccounts(),
      get().loadExchangeRates()
    ])
  },


  async initNewInvoice(invoiceTypeId, subjectInvoiceId) {
    try {
      const vars = invoiceModuleVars.find(v => v.mainInvoiceTypeId === invoiceTypeId || v.returnInvoiceTypeId === invoiceTypeId)
      const isReturnInvoice = ifReturnInvoice(invoiceTypeId)
      set((state) => {
        state.vars = vars
        state.varsSecond = { isReturnInvoice }
        state.pageStatus = "loading"
        state.invoice = {
          invoiceTypeId: invoiceTypeId,
          invoiceDate: formatDateToISO(new Date()),
        }
        state.errors = {}
        state.validationWasMade = false
        state.autoFocusOn = false
        state.editorMode = true
        state.invoicesSubjectToReturn = isReturnInvoice ? [] : state.invoicesSubjectToReturn
      })

      await get().loadRequirements()

      if (subjectInvoiceId) {
        get().loadSubjectInvoiceInfo(subjectInvoiceId)
      }
      else {
        set(state => {
          state.pageStatus = "ready"
        })
        useInvoiceDetailUnit.getState().initialize()
      }

    }
    catch (errorId) {
      set((state) => {
        state.pageStatus = typeof errorId === "string" ? errorId : "unspecified"
      })
      console.error("ff8b6fa2c9864628a89eeaf2e9e959d0", errorId)
    }
  },



  async loadInvoice(invoiceTypeId, invoiceId) {
    try {
      const vars = invoiceModuleVars.find(v => v.mainInvoiceTypeId === invoiceTypeId)

      set((state) => {
        state.vars = vars
        state.pageStatus = "loading"
        state.editorMode = false
      })

      await get().loadRequirements()
      const { invoice, rows, payments, returns, invoicesSubjectToReturn } = await getInvoice(invoiceId)

      if ([invoiceTypeId, vars.returnInvoiceTypeId].includes(invoice.invoiceTypeId) !== true) {
        throw "not-found"
      }


      const summary = {
        sumOfBasePrices: invoice.sumOfBasePrices,
        sumOfRowDiscounts: invoice.sumOfRowDiscounts,
        subtotalDiscountType: invoice.subtotalDiscountType,
        subtotalDiscountPercent: invoice.subtotalDiscountPercent || undefined,
        subtotalDiscountAmount: invoice.subtotalDiscountAmount || undefined,
        grossTotal: invoice.grossTotal,
        stoppagePercent: invoice.stoppagePercent,
        stoppageAmount: invoice.stoppageAmount,
        sumOfConsumptionTaxAmounts: invoice.sumOfConsumptionTaxAmounts,
        sumOfCommunicationTaxAmounts: invoice.sumOfCommunicationTaxAmounts,
        sumOfAccommodationTaxAmounts: invoice.sumOfAccommodationTaxAmounts,
        sumOfVatAmounts: invoice.sumOfVatAmounts,
        vatTable: JSON.parse(invoice.vatTable),
        sumOfWithholdingAmounts: invoice.sumOfWithholdingAmounts,
        withholdingTable: JSON.parse(invoice.withholdingTable),
        grandTotal: invoice.grandTotal,
        grandTotalTry: invoice.grandTotalTry,
        effectiveTotal: invoice.effectiveTotal,
      }
      useInvoiceDetailUnit.getState().initialize(rows, summary)
      usePaymentUnit.getState().initialize(payments)

      set((state) => {
        state.invoice = invoice
        state.invoicesSubjectToReturn = invoicesSubjectToReturn
        state.varsSecond = { isReturnInvoice: ifReturnInvoice(invoice.invoiceTypeId) }
        state.returns = returns
        state.pageStatus = "ready"
      })

    } catch (errorId) {
      set((state) => {
        state.pageStatus = errorId
      })
    }


  },


  async loadSubjectInvoiceInfo(subjectInvoiceId, options) {
    try {
      const vars = get().vars
      var subjectInvoiceList = get().invoicesSubjectToReturn
      const firstSubjectInvoice = subjectInvoiceList.some(r => r.subjectInvoiceId > 0) === false
      if (subjectInvoiceList.some(r => r.subjectInvoiceId === subjectInvoiceId)) {
        showWarningMessage("Bu fatura daha önce eklendi")
        set(state => { state.pageStatus = "ready" })
        return false
      }

      const { invoice: subjectInvoice, rows } = await getSubjectInvoiceInfo(subjectInvoiceId)
      if (subjectInvoice.invoiceTypeId !== vars.returnInvoiceTypeId) {
        throw "not-found-or-non-returnable"
      }



      const summary = firstSubjectInvoice ? {
        subtotalDiscountType: subjectInvoice.subtotalDiscountType,
        subtotalDiscountPercent: subjectInvoice.subtotalDiscountPercent || undefined,
        subtotalDiscountAmount: subjectInvoice.subtotalDiscountAmount || undefined,
        stoppagePercent: subjectInvoice.stoppagePercent,
      } : {}

      useInvoiceDetailUnit.getState().addRowsAndRecalculate(rows, summary, options)
      const invoice = reconcileInvoices(get().invoice, subjectInvoice)
      const subject = {
        dbRowId: uniqueId("-"),
        subjectInvoiceId: subjectInvoice.subjectInvoiceId,
        subjectInvoiceNumber: subjectInvoice.subjectInvoiceNumber,
        subjectInvoiceDate: subjectInvoice.subjectInvoiceDate
      }
      set(state => {
        state.invoice = invoice
        state.pageStatus = "ready"
        if (options?.append === true) state.invoicesSubjectToReturn.push(subject)
        else state.invoicesSubjectToReturn = [subject]
      })
    }
    catch (errorId) {
      console.error(errorId)
      showErrorMessage("'İadeye Konu Olan Fatura' bilgileri yüklenemedi")
      set(state => {
        state.pageStatus = "ready"
      })
    }
  },

  async addSubjectInvoice(invoiceId) {
    set(state => {
      state.pageStatus = "running"
    })
    useInvoicePickerUnit.getState().changeState({ pageStatus: "running" })

    if (await get().loadSubjectInvoiceInfo(invoiceId, { append: true }) !== false) {
      useInvoicePickerUnit.getState().changeState({ pageStatus: "closed" })
    }
    else {
      useInvoicePickerUnit.getState().changeState({ pageStatus: "ready" })
    }
    set(state => {
      state.errors.invoicesSubjectToReturn = undefined
    })
  },

  removeSubjectInvoice(rowIndex) {
    set(state => {
      state.invoicesSubjectToReturn = state.invoicesSubjectToReturn.filter((_, index) => index !== rowIndex)
    })
  },

  addUnrecordedSubjectInvoiceInfo({ subjectInvoiceNumber, subjectInvoiceDate }) {
    set(state => {
      state.invoicesSubjectToReturn.push({
        dbRowId: uniqueId("-"),
        subjectInvoiceNumber,
        subjectInvoiceDate
      })
      state.errors.invoicesSubjectToReturn = undefined
    })
  },

  changeSubjectInvoice(rowIndex, values) {
    set(state => {
      const row = { ...state.invoicesSubjectToReturn[rowIndex], ...values }

      if (state.validationWasMade) {
        const [_, rowFinal] = validateSubjectInvoice(row)
        state.invoicesSubjectToReturn[rowIndex] = rowFinal
      }
      else {
        state.invoicesSubjectToReturn[rowIndex] = row
      }

    })
  },

  reset() {
    set((state) => ({ ...state, ...initialState }))
    usePaymentUnit.getState().reset()
    useInvoiceDetailUnit.getState().reset()
  },

  validateSubjectInvoices() {
    if (get().varsSecond.isReturnInvoice !== true) return true;

    if (get().invoicesSubjectToReturn.length === 0) {
      set(state => {
        state.errors.invoicesSubjectToReturn = "'İadeye Konu Olan Fatura' bilgisi eklemelisiniz"
      })
      return false
    }

    let valid = true
    set(state => {
      for (let i in state.invoicesSubjectToReturn) {
        const row = state.invoicesSubjectToReturn[i]
        const [currentRowIsValid, rowFinal] = validateSubjectInvoice(row)
        valid &&= currentRowIsValid
        state.invoicesSubjectToReturn[i] = rowFinal
      }
    })

    console.log("valid", valid);
    return valid
  },

  async createInvoice() {
    try {
      set((state) => {
        state.pageStatus = "running"
      })

      const { invoice, invoicesSubjectToReturn } = get()
      const { summary, rows } = useInvoiceDetailUnit.getState()
      const paymentList = usePaymentUnit.getState().paymentList
      const payload = {
        ...prepareInvoiceData(invoice),
        ...prepareSummary(summary),
        rows: prepareInvoiceRows(rows),
        payments: preparePayments(paymentList, invoice.companyId),
        invoicesSubjectToReturn
      }

      await createInvoice(payload)
      showSuccessMessage("Kayıt işlemi tamamlandı")

      return true
    } catch {
      showErrorMessage("Hata oluştu")
    } finally {
      set((state) => {
        state.pageStatus = "ready"
      })
    }
  },

  async updateInvoice() {
    try {
      set((state) => {
        state.pageStatus = "running"
      })
      const { invoice, invoicesSubjectToReturn } = get()
      const { summary, rows } = useInvoiceDetailUnit.getState()
      const payload = {
        ...prepareInvoiceData(invoice),
        ...prepareSummary(summary),
        rows: prepareInvoiceRows(rows),
        invoicesSubjectToReturn
      }


      await updateInvoice(payload)
      return true
    } catch {
      showErrorMessage("Hata oluştu")
    } finally {
      set((state) => {
        state.pageStatus = "ready"
      })
    }
  },

  async deleteInvoice() {
    try {
      set((state) => {
        state.pageStatus = "running"
      })
      var invoiceId = get().invoice.invoiceId
      await deleteInvoice(invoiceId)

      return true
    } catch {
      showErrorMessage("Hata oluştu")
    } finally {
      set((state) => {
        state.pageStatus = "ready"
      })
    }



  },

  async loadExchangeRates() {
    const invoiceDate =
      get().invoice.invoiceDate || formatDateToISO(new Date())
    const exchangeRates = await fetchExchangeRates(invoiceDate)
    set((state) => {
      state.exchangeRates = exchangeRates
    })
  },

})))

export default useInvoiceUnit

function getInvoice(invoiceId) {
  const { appApiAddress, appToken } = useAppInfo.getState()
  return new HttpRequest(appApiAddress, `/metot/get-invoice/${invoiceId}`)
    .addHeader("authorization", appToken)
    .get()
}

function getSubjectInvoiceInfo(invoiceId) {
  const { appApiAddress, appToken } = useAppInfo.getState()
  return new HttpRequest(appApiAddress, `/metot/get-subject-invoice-info/${invoiceId}`)
    .addHeader("authorization", appToken)
    .get()
}

function createInvoice(invoice) {
  const { appApiAddress, appToken } = useAppInfo.getState()
  return new HttpRequest(appApiAddress, "/metot/create-invoice")
    .addHeader("authorization", appToken)
    .postAsJson(invoice)
}

function updateInvoice(invoice) {
  const { appApiAddress, appToken } = useAppInfo.getState()
  return new HttpRequest(appApiAddress, "/metot/update-invoice")
    .addHeader("authorization", appToken)
    .putAsJson(invoice)
}


function deleteInvoice(invoiceId) {
  const { appApiAddress, appToken } = useAppInfo.getState()
  return new HttpRequest(appApiAddress, `/metot/delete-invoice/${invoiceId}`)
    .addHeader("authorization", appToken)
    .delete()
}


function prepareInvoiceData(ivc) {

  return {
    ...ivc,
    invoiceCurrencyId: ivc.invoiceCurrencyId || 1,
    effectiveCurrencyId: ivc.effectiveCurrencyId || ivc.invoiceCurrencyId || 1,

    invoiceExchangeRate: ivc.invoiceExchangeRate || 1,
    invoiceExchangeRateDate: ivc.invoiceExchangeOption === 3 ? undefined : ivc.invoiceExchangeRateDate,

    effectiveExchangeRate: ivc.effectiveExchangeRate || 1,
    effectiveExchangeRateDate: ivc.effectiveExchangeOption === 3 ? undefined : ivc.effectiveExchangeRateDate,
  }
}

function prepareSummary(summary) {
  const vatTable = {}
  for (const k in Object(summary.vatTable)) {
    vatTable[k] = roundFloat2(summary.vatTable[k])
  }
  const withholdingTable = {}
  for (const k in Object(summary.withholdingTable)) {
    withholdingTable[k] = roundFloat2(summary.withholdingTable[k])
  }


  let subtotalDiscountPercent = roundFloat6(summary.subtotalDiscountPercent || 0)
  let subtotalDiscountAmount = roundFloat2(summary.subtotalDiscountAmount || 0)
  let subtotalDiscountType = summary.subtotalDiscountType || 0
  if ((subtotalDiscountPercent > 0 && subtotalDiscountAmount > 0 && subtotalDiscountType > 0) !== true) {
    subtotalDiscountPercent = subtotalDiscountAmount = subtotalDiscountType = undefined
  }
  return {
    ...summary,
    subtotalDiscountPercent,
    subtotalDiscountAmount,
    subtotalDiscountType,
    vatTable,
    withholdingTable,
  }
}

function prepareInvoiceRows(rows) {
  return rows.map(r => {


    let discountType = r.discountType || 0
    let discountPercent = roundFloat6(r.discountPercent || 0)
    let discountAmount = roundFloat2(r.discountAmount || 0)
    if ((discountType > 0 && discountPercent > 0 && discountAmount > 0) !== true) {
      discountType = discountPercent = discountAmount = undefined
    }


    let consumptionTaxType = r.consumptionTaxType || 0
    let consumptionTaxPercent = roundFloat6(r.consumptionTaxPercent || 0)
    let consumptionTaxAmount = roundFloat2(r.consumptionTaxAmount || 0)
    if ((consumptionTaxType > 0 && consumptionTaxPercent > 0 && consumptionTaxAmount > 0) !== true) {
      consumptionTaxType = consumptionTaxPercent = consumptionTaxAmount = undefined
    }

    let communicationTaxType = r.communicationTaxType || 0
    let communicationTaxPercent = roundFloat6(r.communicationTaxPercent || 0)
    let communicationTaxAmount = roundFloat2(r.communicationTaxAmount || 0)
    if ((communicationTaxType > 0 && communicationTaxPercent > 0 && communicationTaxAmount > 0) !== true) {
      communicationTaxType = communicationTaxPercent = communicationTaxAmount = undefined
    }


    return {
      ...r,
      basePrice: roundFloat6(r.basePrice),

      discountType, discountPercent, discountAmount,

      vatAmount: roundFloat2(r.vatAmount || 0),
      vatAmountOfConsumptionTax: roundFloat2(r.vatAmountOfConsumptionTax || 0),
      withholdingPercent: r.withholdingPercent ?? 0,
      withholdingOfVatAmount: roundFloat2(r.withholdingOfVatAmount || 0),
      withholdingOfVatAmountOfConsumptionTax: roundFloat2(r.withholdingOfVatAmountOfConsumptionTax || 0),

      consumptionTaxType, consumptionTaxPercent, consumptionTaxAmount,
      communicationTaxType, communicationTaxPercent, communicationTaxAmount,

      communicationTaxAmountOfConsumptionTax: roundFloat2(r.communicationTaxAmountOfConsumptionTax || 0),
      accommodationTaxAmount: roundFloat2(r.accommodationTaxAmount || 0),
      units: undefined,
      loading: undefined,
      errors: undefined,
    }
  })
}

function preparePayments(payments, companyId) {
  return payments.map((p) => {
    const cashMovement = p.cashMovement && { ...p.cashMovement, companyId }
    const check = p.check && makeCheckReadyForSending(p.check)
    return { ...p, cashMovement, check }
  })
}




function reconcileInvoices(invoice, subjectInvoice) {
  return {
    ...invoice,
    invoiceTypeId: subjectInvoice.invoiceTypeId,
    companyId: subjectInvoice.companyId,
    companyName: subjectInvoice.companyName,
    dueDate: invoice.dueDate ?? subjectInvoice.dueDate,

    invoiceCurrencyId: invoice.invoiceCurrencyId ?? subjectInvoice.invoiceCurrencyId,
    invoiceExchangeOption: invoice.invoiceExchangeOption ?? subjectInvoice.invoiceExchangeOption,
    invoiceExchangeRateDate: invoice.invoiceExchangeRateDate ?? subjectInvoice.invoiceExchangeRateDate,
    invoiceExchangeRate: invoice.invoiceExchangeRate ?? subjectInvoice.invoiceExchangeRate,

    effectiveCurrencyId: invoice.effectiveCurrencyId ?? subjectInvoice.effectiveCurrencyId,
    effectiveExchangeOption: invoice.effectiveExchangeOption ?? subjectInvoice.effectiveExchangeOption,
    effectiveExchangeRateDate: invoice.effectiveExchangeRateDate ?? subjectInvoice.effectiveExchangeRateDate,
    effectiveExchangeRate: invoice.effectiveExchangeRate ?? subjectInvoice.effectiveExchangeRate,

    subtotalDiscountType: invoice.subtotalDiscountType ?? subjectInvoice.subtotalDiscountType,
    subtotalDiscountPercent: invoice.subtotalDiscountPercent ?? subjectInvoice.subtotalDiscountPercent,
    subtotalDiscountAmount: invoice.subtotalDiscountAmount ?? subjectInvoice.subtotalDiscountAmount,
    stoppagePercent: invoice.stoppagePercent ?? subjectInvoice.stoppagePercent
  }
}


function ifReturnInvoice(invoiceTypeId) {
  switch (invoiceTypeId) {
    case INVOICE_TYPES.SELLING_INVOICE_RETURN:
    case INVOICE_TYPES.CANCELLED_SELLING_INVOICE_RETURN:
    case INVOICE_TYPES.EXPENSE_INVOICE_RETURN:
    case INVOICE_TYPES.CANCELLED_EXPENSE_INVOICE_RETURN:
      return true
    default:
      return false
  }
}

function validateSubjectInvoice(row) {
  let valid = true;
  if (row.subjectInvoiceId > 0 === false) {
    if (!row.subjectInvoiceNumber || row.subjectInvoiceNumber.trim() === "") {
      row.subjectInvoiceNumberError = REQUIRED_MESSAGE
      valid = false;
    }
    else delete row.subjectInvoiceNumberError


    if (!row.subjectInvoiceDate || row.subjectInvoiceDate.length !== 10) {
      row.subjectInvoiceDateError = REQUIRED_MESSAGE
      valid = false;
    } else delete row.subjectInvoiceDateError
  }
  return [valid, row]
}

/****************
(*1) due to double run of useEffect in dev mode
****************/