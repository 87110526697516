import HttpRequest from "@/components/httpRequest";
import { formatDateToISO, formatMoney22, fromIsoToTr } from "@/components/utils";
import useAppInfo, { focusSaveButton, showErrorMessage, showSuccessMessage } from "@/modules/app/use-app-info";
import useBankAccountListUnit from "@/modules/bank-account/use-bank-account-list-unit";
import { modules } from "@/modules/common/modules";
import useCompanyListUnit from "@/modules/company/company-list-unit/use-company-list-unit";
import useCurrencyListUnit from "@/modules/currency/use-currency-list-unit";
import useInvoiceUnit from "@/modules/invoice/invoice-unit/use-invoice-unit";
import { addPaymentToInvoice } from "@/modules/invoice/payment/use-payment-unit";
import useVaultAccountListUnit from "@/modules/vault-account/use-vault-account-list-unit";
import _isEmpty from "lodash/isEmpty";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { checkModuleVars } from "../check-module-vars";

const initialState = {
  reloadKey: 0,
  pageStatus: undefined,
  editorMode: undefined,
  check: { checkNumber: "999", dueDate: "2024-12-31" },
  transactions: [],
  errors: {},
  vars: {},
  currentTransaction: undefined,
  validationWasMade: false,
};

export const useCheckUnit = create(immer((set, get) => ({
  ...initialState,
  async initialize(check, editorMode) {
    const checkId = check.checkId;
    const checkTypeId = check.checkTypeId;

    try {
      set((state) => {
        state.pageStatus = "loading";
        state.editorMode = editorMode ?? isNaN(checkId);
        state.check = {
          ...initialState.check,
          ...check,
          transactionDate: isNaN(checkId) ? formatDateToISO(new Date()) : undefined
        };
        state.vars = checkModuleVars.find((v) => v.checkTypeId === checkTypeId);
      });

      await Promise.all([
        useCurrencyListUnit.getState().loadCurrencies(),
        useBankAccountListUnit.getState().loadBankAccounts(),
        useVaultAccountListUnit.getState().loadVaultAccounts(),
        useCompanyListUnit.getState().loadCompanies(),
        checkId && get().loadCheck(checkId, checkTypeId),
      ]);
      set((state) => {
        state.pageStatus = "ready";
      });
    } catch (errorId) {
      set((state) => {
        state.pageStatus = errorId;
      });
    }
  },

  reset() {
    set((state) => ({ ...state, ...initialState }));
  },

  changeCheckInfo(values) {
    set((state) => {
      // if ("currencyId" in values || "effectiveCurrencyId" in values) {
      //   const currencyId = values.currencyId || state.check.currencyId;
      //   const effectiveCurrencyId =
      //     values.effectiveCurrencyId ||
      //     state.check.effectiveCurrencyId ||
      //     currencyId;
      //   if (currencyId == effectiveCurrencyId) {
      //     values = {
      //       ...values,
      //       exchangeRate: 1,
      //       exchangeRateDate: undefined,
      //       exchangeOption: undefined,
      //     };
      //     if (state.check.isPayment) {
      //       values.amount = state.check.effectiveAmount;
      //       values.maxAmount = state.check.maxEffectiveAmount;
      //     } else values.effectiveAmount = state.check.amount;
      //   }
      // }
      state.check = { ...state.check, ...values };
    });
    const { validate, validationWasMade } = get();
    if (validationWasMade) validate();
  },

  async loadCheck(checkId, checkTypeId) {
    const { check, transactions } = await fetchCheck(checkId);
    if (check.checkTypeId !== checkTypeId) throw "not-found";
    set((state) => {
      state.check = check;
      state.transactions = [
        {
          checkTransactionId: 0,
          transactionDateFmtd: fromIsoToTr(check.transactionDate),
          statusName: state.vars.firstTransactionStatusName,
        },
        ...transactions,
      ];
    });
  },

  validate() {
    const errors = findErrors(get().check);
    set((state) => {
      state.errors = { ...errors };
      state.validationWasMade = true;
    });
    return _isEmpty(errors);
  },

  async save() {
    try {
      set((state) => {
        state.pageStatus = "running";
      });

      const check = makeCheckReadyForSending(get().check);

      if (check.checkId) await updateCheck(check);
      else await createCheck(check);
      showSuccessMessage("Kayıt işlemi tamamlandı");
      return true;
    } catch {
      showErrorMessage("Hata oluştu");
      return false;
    } finally {
      set((state) => {
        state.pageStatus = "ready";
      });
    }
  },

  async deleteCheck() {
    try {
      set((state) => {
        state.pageStatus = "running";
      });
      const checkId = get().check.checkId;
      await deleteCheck(checkId);
      return true;
    } catch (errorId) {
      switch (errorId) {
        case "not-found":
          return true;
        default:
          showErrorMessage("Hata oluştu");
      }
    } finally {
      set((state) => {
        state.pageStatus = "ready";
      });
    }
  },

  async deleteCheckTransaction(checkTransactionId) {
    try {
      set((state) => {
        state.pageStatus = "running";
      });

      await deleteCheckTransaction(checkTransactionId);

      success();
    } catch (errorId) {
      switch (errorId) {
        case "not-latest-transaction":
          showErrorMessage("Geçersiz işlem");
          break;
        case "not-found":
          return success();
        default:
          showErrorMessage("Hata oluştu");
      }
      set((state) => {
        state.pageStatus = "ready";
      });
    }
    function success() {
      set((state) => {
        showSuccessMessage("Silme işlemi tamamlandı.");
        state.reloadKey++;
        state.currentTransaction = undefined;
      });
    }
  },

  reload() {
    set((state) => {
      state.editorMode = false;
      state.reloadKey++;
    });
  },

  setEditorMode(value) {
    set((state) => {
      state.editorMode = value;
    });
  },

  changeCurrentTransaction(values) {
    set((state) => {
      state.currentTransaction = { ...state.currentTransaction, ...values };
    });
  },

  resetCurrentTransaction() {
    set((state) => {
      state.currentTransaction = undefined;
      state.reloadKey++;
    });
  },

  async saveTransaction(fromButton) {
    if (!fromButton) return focusSaveButton();

    try {
      set((state) => {
        state.pageStatus = "running";
      });

      await saveTransaction(get().currentTransaction);
      showSuccessMessage("Kayıt işlemi tamamlandı");
      set((state) => {
        state.reloadKey++;
        // state.currentTransaction = undefined;
      });
    } catch {
      showErrorMessage("Hata oluştu");
    } finally {
      set((state) => {
        state.pageStatus = "ready";
      });
    }
  },


  async addPaymentToInvoice(payment) {
    try {
      set((state) => {
        state.pageStatus = "running";
      });
      const invoiceId = useInvoiceUnit.getState().invoice.invoiceId;
      await addPaymentToInvoice(invoiceId, payment);
      showSuccessMessage("Kayıt işlemi tamamlandı");
      return true;
    } catch {
      showErrorMessage("Hata oluştu");
    } finally {
      set((state) => {
        state.pageStatus = "ready";
      });
    }

  },



})),
);

function createCheck(check) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/create-check")
    .addHeader("authorization", appToken)
    .postAsJson(check);
}
function updateCheck(check) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/update-check")
    .addHeader("authorization", appToken)
    .putAsJson(check);
}

function saveTransaction(transaction) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/create-check-transaction")
    .addHeader("authorization", appToken)
    .postAsJson(transaction);
}

export function fetchCheck(checkId) {
  const { appApiAddress, appToken } = useAppInfo.getState();

  return new HttpRequest(appApiAddress, `/metot/get-check/${checkId}`)
    .addHeader("authorization", appToken)
    .get();
}

export function deleteCheck(checkId) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, `/metot/delete-check/${checkId}`)
    .addHeader("authorization", appToken)
    .delete();
}


function deleteCheckTransaction(checkTransactionId) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, `/metot/delete-check-transaction/${checkTransactionId}`)
    .addHeader("authorization", appToken)
    .delete();
}


export function makeCheckReadyForSending(check) {
  return {
    ...check,
    currencyId: check.currencyId || 1,
    effectiveCurrencyId: check.effectiveCurrencyId || check.currencyId || 1,
    exchangeRate: check.exchangeRate ?? 1,
  };
}

const REQUIRED_MESSAGE = "Zorunlu alan";
const findErrors = (check) => {
  const errors = {};
  if (!check.companyId && !check.isPayment) errors.companyId = REQUIRED_MESSAGE;
  if (!check.transactionDate) errors.transactionDate = REQUIRED_MESSAGE;
  if (!check.dueDate) errors.dueDate = REQUIRED_MESSAGE;
  if (!check.checkNumber) errors.checkNumber = REQUIRED_MESSAGE;

  if (isNaN(check.amount)) errors.amount = REQUIRED_MESSAGE;
  else if (!check.amount > 0) errors.amount = "Değer sıfırdan büyük olmalıdır";

  if (check.checkTypeId === modules.FIRM_CHECK && !check.bankAccountId) {
    errors.bankAccountId = REQUIRED_MESSAGE;
  }

  const currencyId = check.currencyId || 1;
  const effectiveCurrencyId = check.effectiveCurrencyId || currencyId;

  if (currencyId !== effectiveCurrencyId) {
    if (isNaN(check.effectiveAmount)) errors.effectiveAmount = REQUIRED_MESSAGE;
    else if (!check.effectiveAmount > 0) {
      errors.effectiveAmount = "Değer sıfırdan büyük olmalıdır";
    }
    else if (check.maxEffectiveAmount > 0 && check.effectiveAmount > check.maxEffectiveAmount) {
      errors.effectiveAmount = maxMessage();
    }
  }
  else if (check.maxEffectiveAmount > 0 && check.amount > check.maxEffectiveAmount) {
    errors.amount = maxMessage();
  }

  function maxMessage() {
    return <>"<strong className="mx-0.5">{formatMoney22(check.maxEffectiveAmount)}</strong>" değerinden büyük olamaz</>;
  }

  return errors;
};
