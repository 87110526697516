const { differenceInMinutes } = require("date-fns");

const cache = new Map();
export function getFromCache(key) {
  const cacheArr = cache.get(key);
  if (cacheArr) {
    const [data, timeAdded] = cacheArr;
    if (differenceInMinutes(new Date(), timeAdded) <= 5) {
      return data;
    }
  }
}

export function putToCache(key, data) {
  cache.set(key, [data, new Date()]);
}
