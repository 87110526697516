import { getFromCache, putToCache } from "@/components/cache";
import HttpRequest from "@/components/httpRequest";
import MetotCgRepeat from "@/components/icon-center/MetotCgRepeat";
import MetotFaRegCircle from "@/components/icon-center/MetotFaRegCircle";
import MetotFaRegCircleDot from "@/components/icon-center/MetotFaRegCircleDot";
import MetotHiOutlineExclamation from "@/components/icon-center/MetotHiOutlineExclamation";
import Input from "@/components/input/input";
import { PopDiv } from "@/components/pop-div";
import { formatMoney24, fromIsoToTr, roundFloat4 } from "@/components/utils";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import useAppInfo from "../app/use-app-info";
import { CurrencyIcon } from "./currency-icon";

export function ExchangeOptionPicker({
  currencyIdFirst, currencyIdSecond, value: initialValues,
  onChange, reverseView: reverseViewProp, disabled }) {

  const [status, setStatus] = useState("loading");
  const [exchangeRates, setExchangeRates] = useState();
  const [values, setValues] = useState(initialValues);
  const [reverseView, setReverseView] = useState(reverseViewProp || false);
  const firstAndSecondSame = currencyIdFirst === currencyIdSecond || !currencyIdFirst || !currencyIdSecond;

  function changeValue(newValues) {
    setValues((options) => ({ ...options, ...newValues }));
  }

  useEffect(() => {
    onChange(values);
  }, [values]);

  useEffect(() => {
    if (firstAndSecondSame) return setValues({ exchangeOption: undefined, exchangeRateDate: undefined, exchangeRate: 1 });
    if (!exchangeRates) return;
    const exchangeRateDate = exchangeRates[0]["date"];

    if (initialValues.exchangeOption === 3) {
      setValues((values) => ({ ...values, exchangeRateDate: undefined }));
      return;
    }

    const priceFieldName = initialValues.exchangeOption === 1 ? "buyingPrice" : "sellingPrice";

    const firstRate = currencyIdFirst === 1 ? 1 : exchangeRates.find((rates) => rates.currencyId === currencyIdFirst)[priceFieldName];
    const secondRate = currencyIdSecond === 1 ? 1 : exchangeRates.find((rates) => rates.currencyId === currencyIdSecond)[priceFieldName];

    setValues((values) => ({
      ...values,
      exchangeOption: values.exchangeOption || 1,
      exchangeRateDate,
      exchangeRate: roundFloat4(firstRate / secondRate)
    }));
  }, [currencyIdFirst, currencyIdSecond, firstAndSecondSame, exchangeRates, initialValues.exchangeOption]);


  useEffect(() => {
    if (firstAndSecondSame) return;
    setStatus("loading");
    fetchExchangeRates(initialValues.exchangeRateDate)
      .then(setExchangeRates)
      .then(() => setStatus("ready"))
      .catch(setStatus);
  }, [initialValues.exchangeRateDate, firstAndSecondSame]);

  const exchangeRateDateFormatted = useMemo(() =>
    initialValues.exchangeRateDate && fromIsoToTr(initialValues.exchangeRateDate),
    [initialValues.exchangeRateDate]);

  const exchangeRate = reverseView
    ? roundFloat4(1 / initialValues.exchangeRate)
    : initialValues.exchangeRate;

  return (
    <PopDiv showArrow
      trigger={
        <button type="button" disabled={disabled || status === "loading" || status === "unspecified"}
          className={clsx(
            firstAndSecondSame ? "hidden" : "flex",
            "truncate items-center justify-center gap-1 text-xs text-slate-400 hover:text-slate-800 data-[open]:font-bold data-[open]:!text-slate-800")}
        >
          {status === "loading" ? "Kur bilgileri yükleniyor..." : status === "unspecified" ? (
            <span className="flex items-center gap-2 text-red-500">
              <MetotHiOutlineExclamation /> Kur bilgileri yüklenemedi
            </span>
          ) : (
            <>
              <span>1</span>
              <CurrencyIcon currencyId={reverseView ? currencyIdSecond : currencyIdFirst} />
              <span>= {formatMoney24(roundFloat4(exchangeRate || 0))}</span>
              <CurrencyIcon currencyId={reverseView ? currencyIdFirst : currencyIdSecond} />
            </>
          )}
        </button>
      }
    >
      <div className="flex flex-col gap-1 rounded bg-slate-800 p-3 text-sm text-white shadow-lg shadow-slate-800">
        <div className="h-6">
          <button
            type="button"
            className="flex items-center gap-2"
            onClick={() => changeValue({ exchangeOption: 1 })}
          >
            {initialValues.exchangeOption === 1 ? (
              <MetotFaRegCircleDot />
            ) : (
              <MetotFaRegCircle />
            )}
            TCMB Alış Kuru
          </button>
        </div>

        <div className="h-6">
          <button
            type="button"
            className="flex items-center gap-2"
            onClick={() => changeValue({ exchangeOption: 2 })}
          >
            {initialValues.exchangeOption === 2 ? (
              <MetotFaRegCircleDot />
            ) : (
              <MetotFaRegCircle />
            )}
            TCMB Satış Kuru
          </button>
        </div>

        <div className="flex h-6 items-center gap-2">
          <button
            type="button"
            className="flex items-center gap-2"
            onClick={() =>
              changeValue({ exchangeOption: 3, exchangeRateDate: undefined })
            }
          >
            {initialValues.exchangeOption === 3 ? (
              <MetotFaRegCircleDot />
            ) : (
              <MetotFaRegCircle />
            )}
            Diğer
          </button>
          <div className="w-24 ps-5">
            {initialValues.exchangeOption === 3 && (
              <Input
                value={exchangeRate}
                onChange={(val) =>
                  changeValue({
                    exchangeRate: reverseView ? roundFloat4(1 / val) : val,
                  })
                }
                type={"number"}
                className={"w-full py-1 text-xs text-black"}
                decimalScale={4}
                autoFocus={true}
              />
            )}
          </div>
        </div>

        <div className="mt-2 flex items-center justify-between gap-5">
          <span
            className={clsx(
              "text-xs text-slate-200",
              initialValues.exchangeOption === 3 && "invisible",
            )}
          >
            Kur Tarihi: {exchangeRateDateFormatted}
          </span>
          <button
            type="button"
            onClick={() => setReverseView((v) => !v)}
            className="hover:text-sky-400"
          >
            <MetotCgRepeat size={"1.5rem"} />
          </button>
        </div>
      </div>
    </PopDiv>
  );
}

export async function fetchExchangeRates(date, options) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  const path = "/metot/get-exchange-rates";
  const cached = getFromCache(`${path}/${date}`);
  if (cached) return cached;

  const exchangeRates = await new HttpRequest(appApiAddress, `${path}/${date}`)
    .addHeader("authorization", appToken)
    .get();

  putToCache(`${path}/${date}`, exchangeRates);

  if (options?.asArray) {
    return exchangeRates.reduce(
      function (array, row) {
        array[row.currencyId] = row.buyingPrice;
        return array;
      },
      [0, 1],
    );
  }
  return exchangeRates;
}
