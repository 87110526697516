import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import HttpRequest from "@/components/httpRequest";
import useAppInfo from "@/modules/app/use-app-info";
const useCurrencyListUnit = create(
  immer((set, get) => ({
    currencies: [],
    activeCurrencies: [],
    clientDataVersion: 0,
    async loadCurrencies() {
      const { currencies, clientDataVersion } = get();
      if (currencies.length > 0) return;
      const { currencyList } = await getCurrencies(clientDataVersion);
      const activeCurrencies = currencyList.filter((c) => c.recordStatus === 1);

      set((state) => {
        state.currencies = currencyList;
        state.activeCurrencies = activeCurrencies;
      });
    },
  })),
);

export default useCurrencyListUnit;

function getCurrencies(clientDataVersion) {
  const { appApiAddress, appToken } = useAppInfo.getState();

  return new HttpRequest(
    appApiAddress,
    `/metot/get-currency-list?clientDataVersion=${clientDataVersion}`,
  )
    .addHeader("authorization", appToken)
    .get();
}
