export const CASH_MOVEMENT_TYPES = Object.freeze({
  OUTGOING_TRANSFER: 1501,
  INCOMING_TRANSFER: 1502,
  PAYMENT_BY_HAND: 1503,
  RECEIPT_BY_HAND: 1504,
  DEPOSIT: 1505,
  WITHDRAW: 1506,
  BANK_VIREMENT: 1507,
  VAULT_VIREMENT: 1508,
  BANK_OUTFLOW: 1509,
  BANK_INFLOW: 1510,
  VAULT_OUTFLOW: 1511,
  VAULT_INFLOW: 1512,

  CHECK_RECEIPT_BY_HAND: 1600,
  CHECK_RECEIPT_FROM_EXCHANGE: 1601,
  CHECK_RECEIPT_FROM_ASSURANCE: 1602,

  BILL_RECEIPT_BY_HAND: 1700,
  BILL_RECEIPT_FROM_EXCHANGE: 1701,
  BILL_RECEIPT_FROM_ASSURANCE: 1702,

  CHECK_PAYMENT_BY_HAND: 1811,
  CHECK_PAYMENT_BY_BANK: 1812,

  BILL_PAYMENT_BY_HAND: 1911,
  BILL_PAYMENT_BY_BANK: 1912,
});
