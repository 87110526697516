import { modules } from "@/modules/common/modules";

export const withholdingPercents = [
  { name: "2 / 10  ", pct: 20 },
  { name: "3 / 10  ", pct: 30 },
  { name: "4 / 10  ", pct: 40 },
  { name: "5 / 10  ", pct: 50 },
  { name: "7 / 10  ", pct: 70 },
  { name: "9 / 10  ", pct: 90 },
  { name: "10 / 10  ", pct: 100 },
];

export const INVOICE_TYPES = Object.freeze({
  SELLING_INVOICE: modules.SELLING_INVOICE,
  EXPENSE_INVOICE_RETURN: 1214,
  CANCELLED_SELLING_INVOICE: 1299,
  CANCELLED_EXPENSE_INVOICE_RETURN: 1288,

  EXPENSE_INVOICE: modules.EXPENSE_INVOICE,
  SELLING_INVOICE_RETURN: 1412,
  CANCELLED_EXPENSE_INVOICE: 1499,
  CANCELLED_SELLING_INVOICE_RETURN: 1488,
});
