import HttpRequest from "@/components/httpRequest";
import useAppInfo from "@/modules/app/use-app-info";
import { useProductGroupListUnit } from "@/modules/product-group/product-group-list-unit/use-product-group-list-unit";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const useProductListUnit = create(
  immer((set, get) => ({
    clientDataVersion: 0,
    products: [],
    pageStatus: "loading",
    async initialize() {
      try {
        set((state) => {
          state.pageStatus = "loading";
        });

        await Promise.all([
          useProductGroupListUnit.getState().loadProductGroups(),
          get().loadProducts(),
        ]);

        set((state) => {
          state.pageStatus = "ready";
        });
      } catch (errorId) {
        set((state) => {
          state.pageStatus = errorId;
        });
      }
    },
    async loadProducts() {
      const { clientDataVersion } = get();

      const { productList, dbDataVersion, notModified } =
        await fetchProducts(clientDataVersion);

      if (!notModified) {
        set((state) => {
          state.clientDataVersion = dbDataVersion;
          state.products = productList;
          state.sellingProductOptions = prepareOptions(
            productList,
            1,
            "SATIŞI YAPILANLAR",
          );
          state.expenseOptions = prepareOptions(productList, 2, "GİDERLER");
        });
      }
    },
  })),
);

function fetchProducts(clientDataVersion) {
  const { appApiAddress, appToken } = useAppInfo.getState();

  return new HttpRequest(
    appApiAddress,
    `/metot/get-product-list?clientDataVersion=${clientDataVersion}`,
  )
    .addHeader("authorization", appToken)
    .get();
}

function prepareOptions(products, primaryGroupId, primaryGroupName) {
  const primaryAndOthers = products.reduce(reducer, [[], []]);

  return [
    { label: primaryGroupName, options: primaryAndOthers[0] },
    { label: "DİĞER STOK KARTLARI", options: primaryAndOthers[1] },
  ].filter((g) => g.options.length > 0);

  function reducer(acc, product) {
    const isPrimary = product.groups.includes(primaryGroupId);

    acc[isPrimary ? 0 : 1].push({
      productId: product.productId,
      productName: product.productName,
    });
    return acc;
  }
}

export default useProductListUnit;
