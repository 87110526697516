"use client";

import {
  FloatingArrow,
  FloatingFocusManager,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { cloneElement, useRef, useState } from "react";

export function PopDiv({ children, trigger, showArrow }) {
  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom",
    middleware: [
      offset(12),
      flip(),
      showArrow &&
        arrow({
          element: arrowRef,
          padding: 10,
        }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, { toggle: false });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);
  return (
    <>
      {cloneElement(trigger, {
        ref: refs.setReference,
        [isOpen ? "data-open" : "data-closed"]: "1",
        ...getReferenceProps(),
      })}
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            // returnFocus={true}
            // guards={true}
            // closeOnFocusOut={false}
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                },
              })}
              className="z-20"
            >
              {showArrow && (
                <FloatingArrow
                  width={20}
                  height={12}
                  ref={arrowRef}
                  context={context}
                  className="fill-slate-800"
                />
              )}
              {children}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}
