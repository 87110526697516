import HttpRequest from "@/components/httpRequest";
import { roundFloat2 } from "@/components/utils";
import useAppInfo, { showErrorMessage } from "@/modules/app/use-app-info";
import { CASH_MOVEMENT_TYPES } from "@/modules/cash-movement/cash-movement-types";
import useCashMovementUnit, { deleteCashMovement } from "@/modules/cash-movement/cash-movement-unit/use-cash-movement-unit";
import { deleteCheck, useCheckUnit } from "@/modules/check/check-unit/use-check-unit";
import { modules } from "@/modules/common/modules";
import useInvoiceDetailUnit from "@/modules/invoice/invoice-detail/use-invoice-detail-unit";
import useInvoiceUnit from "@/modules/invoice/invoice-unit/use-invoice-unit";
import uniqueId from "lodash/uniqueId";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const usePaymentUnit = create(immer((set, get) =>
({
   modalPayment: undefined,
   paymentList: [],

   initialize(paymentList) {
      set(state => {
         state.paymentList = paymentList;
      })
   },

   initNewPayment(paymentTypeId) {
      set(state => {
         state.modalPayment = initNewPayment(paymentTypeId)
         state.openInEditorMode = true;
      })
   },

   openPayment(payment, options) {
      set(state => {
         state.modalPayment = payment;
         state.openInEditorMode = options?.openInEditorMode ?? false;
      })
   },

   async saveCashMovement() {
      const { movement: cashMovement, vars } = useCashMovementUnit.getState();
      const isNewInvoice = isNaN(useInvoiceUnit.getState().invoice.invoiceId);
      const modalPayment = get().modalPayment;
      const isNewPayment = isNaN(modalPayment.paymentId)

      const payment = {
         ...modalPayment,
         paymentId: isNewPayment ? parseInt(uniqueId()) * -1 : modalPayment.paymentId,
         paymentTypeName: vars["cashMovementTypeName"],
         amount: cashMovement[vars.effectiveAmountPropName],
         cashMovement
      };


      if (isNewInvoice && isNewPayment) {
         get().insertNewPayment(payment);
      }
      else if (isNewInvoice && !isNewPayment) {
         get().changePayment(payment);
      }
      else if (!isNewInvoice && isNewPayment) {
         await useCashMovementUnit.getState().addPaymentToInvoice(payment)
            && closeModalAndReloadInvoice();
      }
      else if (!isNewInvoice && !isNewPayment) {
         await useCashMovementUnit.getState().save()
            && closeModalAndReloadInvoice();

      }

      function closeModalAndReloadInvoice() {
         set(state => {
            state.modalPayment = undefined;
         });
         useInvoiceUnit.getState().reload();
      }
   },


   async saveCheck() {
      const { check, vars } = useCheckUnit.getState();
      const isNewInvoice = isNaN(useInvoiceUnit.getState().invoice.invoiceId);
      const modalPayment = get().modalPayment;
      const isNewPayment = isNaN(modalPayment.paymentId)

      const payment = {
         ...modalPayment,
         paymentId: isNewPayment ? parseInt(uniqueId()) * -1 : modalPayment.paymentId,
         paymentTypeName: vars["recordName"],
         amount: check.effectiveAmount,
         check
      };

      if (isNewInvoice && isNewPayment) {
         get().insertNewPayment(payment);
      }
      else if (isNewInvoice && !isNewPayment) {
         get().changePayment(payment);
      }
      else if (!isNewInvoice && isNewPayment) {
         await useCheckUnit.getState().addPaymentToInvoice(payment)
            && closeModalAndReloadInvoice();
      }
      else if (!isNewInvoice && !isNewPayment) {
         await useCheckUnit.getState().save()
            && closeModalAndReloadInvoice();

      }

      function closeModalAndReloadInvoice() {
         set(state => {
            state.modalPayment = undefined;
         });
         useInvoiceUnit.getState().reload();
      }
   },

   insertNewPayment(payment) {
      set(state => {
         state.paymentList.push(payment);
         state.modalPayment = undefined;
      });
   },

   changePayment(payment) {
      set(state => {
         const index = state.paymentList.findIndex(p => p.paymentId === payment.paymentId);
         state.paymentList[index] = payment;
         state.modalPayment = undefined;
      })
   },

   changeCompanyIdOfAllPayments(company) {
      company = {
         companyId: company?.companyId,
         companyName: company?.companyName
      };
      set(state => {
         state.paymentList = state.paymentList.map(p => ({
            ...p,
            check: p.check && { ...p.check, ...company },
            cashMovement: p.cashMovement && { ...p.cashMovement, ...company }
         }));
      });
   },

   closeModal() {
      set(state => {
         state.modalPayment = undefined;
      });
   },

   getPaymentTotal() {
      const paymentList = get().paymentList;
      const paymentTotal = paymentList.reduce((sumOfAmounts, currentItem) => sumOfAmounts + currentItem.amount, 0);
      return roundFloat2(paymentTotal);
   },

   reset() {
      set(state => {
         state.paymentList = [];
         state.openInEditorMode = false;
      })
   },

   validatePayments() {
      const effectiveTotal = useInvoiceDetailUnit.getState().summary.effectiveTotal;
      const paymentTotal = get().getPaymentTotal();

      return effectiveTotal - paymentTotal >= 0;
   },

   async deletePayment(deletedPayment) {
      debugger;
      if (deletedPayment.paymentId < 0) {
         set(state => {
            state.paymentList = state.paymentList.filter(p => p.paymentId !== deletedPayment.paymentId);
         });
         return;
      }

      try {

         useInvoiceUnit.getState().setPageStatus("running");

         if (deletedPayment.cashMovementId) {
            await deleteCashMovement(deletedPayment.cashMovementId);
         }
         else if (deletedPayment.checkId) {
            await deleteCheck(deletedPayment.checkId);
         }


         set(state => {
            state.modalPayment = undefined;
         });
         useInvoiceUnit.getState().reload();

      }
      catch (errorId) {
         switch (errorId) {
            case "cannot-be-deleted-due-to-transactions":
               showErrorMessage(`'${deletedPayment.paymentTypeName}' işlem görmüş olduğu için silinemez.`);
               break;
            default:
               showErrorMessage("Hata oluştu");

         }
      }
      finally {
         useInvoiceUnit.getState().setPageStatus("ready");
      }
   }

})));

export default usePaymentUnit;


function initNewPayment(paymentTypeId) {
   const invoice = useInvoiceUnit.getState().invoice;
   const effectiveTotal = useInvoiceDetailUnit.getState().summary.effectiveTotal || 0;
   const effectiveCurrencyId = invoice.effectiveCurrencyId || invoice.invoiceCurrencyId || 1;
   const paymentTotal = usePaymentUnit.getState().getPaymentTotal();
   const defaultAmount = roundFloat2(effectiveTotal - paymentTotal);

   switch (paymentTypeId) {
      case CASH_MOVEMENT_TYPES.OUTGOING_TRANSFER:
      case CASH_MOVEMENT_TYPES.INCOMING_TRANSFER:
      case CASH_MOVEMENT_TYPES.PAYMENT_BY_HAND:
      case CASH_MOVEMENT_TYPES.RECEIPT_BY_HAND:
         return {
            paymentTypeId,
            cashMovement: {
               isPayment: true,
               companyId: invoice.companyId,
               companyName: invoice.companyName,
               cashMovementTypeId: paymentTypeId,
               movementDate: invoice.invoiceDate,
               sourceCurrencyId: effectiveCurrencyId,
               targetCurrencyId: effectiveCurrencyId,
               exchangeRate: 1,
               sourceAmount: defaultAmount,
               targetAmount: defaultAmount,
               maxEffectiveAmount: defaultAmount,
            }
         };
      case modules.FIRM_CHECK:
      case modules.CUSTOMER_CHECK:
      case modules.FIRM_NOTE:
      case modules.CUSTOMER_NOTE:
         return {
            paymentTypeId,
            check: {
               isPayment: true,
               checkTypeId: paymentTypeId,
               transactionDate: invoice.invoiceDate,
               currencyId: effectiveCurrencyId,
               effectiveCurrencyId: effectiveCurrencyId,
               exchangeRate: 1,
               amount: defaultAmount,
               effectiveAmount: defaultAmount,
               maxEffectiveAmount: defaultAmount,
               companyId: invoice.companyId,
               companyName: invoice.companyName
            }
         }
   }
}





export function addPaymentToInvoice(invoiceId, payment) {
   const { appApiAddress, appToken } = useAppInfo.getState();
   return new HttpRequest(appApiAddress, `/metot/add-payment/${invoiceId}`)
      .addHeader("authorization", appToken)
      .postAsJson(payment);
}