import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import HttpRequest from "@/components/httpRequest";
import useAppInfo from "@/modules/app/use-app-info";
const useBankAccountListUnit = create(
  immer((set, get) => ({
    bankAccounts: [],
    bankAccountOptions: [],
    clientDataVersion: 0,
    async loadBankAccounts() {
      const { clientDataVersion } = get();

      const { bankAccountList, dbDataVersion, notModified } =
        await getBankAccounts(clientDataVersion);

      if (notModified) return;

      const bankAccountOptions = prepareBankAccountOptions(bankAccountList);
      set((state) => {
        state.bankAccounts = bankAccountList;
        state.bankAccountOptions = bankAccountOptions;
        state.clientDataVersion = dbDataVersion;
      });
    },
  })),
);

export default useBankAccountListUnit;

function getBankAccounts(clientDataVersion) {
  const { appApiAddress, appToken } = useAppInfo.getState();

  return new HttpRequest(
    appApiAddress,
    `/metot/get-bank-account-list?clientDataVersion=${clientDataVersion}`,
  )
    .addHeader("authorization", appToken)
    .get();
}

function prepareBankAccountOptions(bankAccountList) {
  const options = bankAccountList.map((b) => ({
    bankAccountId: b.bankAccountId,
    bankAccountName: b.bankAccountName,
    currencyId: b.currencyId,
  }));
  return [{ label: "BANKA HESAPLARI", options }];
}
