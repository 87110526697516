import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import HttpRequest from "@/components/httpRequest";
import useAppInfo from "@/modules/app/use-app-info";

const useVaultAccountListUnit = create(
  immer((set, get) => ({
    vaultAccounts: [],
    activeCurrencies: [],
    clientDataVersion: 0,
    async loadVaultAccounts() {
      const { clientDataVersion } = get();

      const { vaultAccountList, dbDataVersion, notModified } =
        await getVaultAccounts(clientDataVersion);

      if (notModified) return;

      const vaultAccountOptions = prepareVaultAccountOptions(vaultAccountList);

      set((state) => {
        state.vaultAccountOptions = vaultAccountOptions;
        state.vaultAccounts = vaultAccountList;
        state.clientDataVersion = dbDataVersion;
      });
    },
  })),
);

export default useVaultAccountListUnit;

function getVaultAccounts(clientDataVersion) {
  const { appApiAddress, appToken } = useAppInfo.getState();

  return new HttpRequest(
    appApiAddress,
    `/metot/get-vault-account-list?clientDataVersion=${clientDataVersion}`,
  )
    .addHeader("authorization", appToken)
    .get();
}

function prepareVaultAccountOptions(vaultAccountList) {
  const options = vaultAccountList.map((v) => ({
    vaultAccountId: v.vaultAccountId,
    vaultAccountName: v.vaultAccountName,
    currencyId: v.currencyId,
  }));
  return [{ label: "KASA HESAPLARI", options }];
}
