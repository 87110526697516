import { getInvoiceList } from "@/modules/invoice/invoice-list/use-invoice-list-unit"
import useInvoiceUnit from "@/modules/invoice/invoice-unit/use-invoice-unit"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

export const useInvoicePickerUnit = create(immer((set, get) => ({
   pageStatus: "closed",
   invoiceList: [],
   setPageStatus: (pageStatus) => {
      set(state => {
         state.pageStatus = pageStatus
      })
   },

   changeState(values) {
      set(state => ({ ...state, ...values }))
   },

   async load() {
      const vars = useInvoiceUnit.getState().vars;
      const companyId = useInvoiceUnit.getState().invoice.companyId;
      set(state => {
         state.pageStatus = "loading"
      })
      const params = {
         invoiceTypeIds: [vars.counterTypeId],
         take: 2147483647,
         skip: 0,
         term: 2024,
         sortKey: "invoiceDate",
         desc: true,
         companyId
      }
      var { list } = await getInvoiceList(params)

      set(state => {
         state.invoiceList = list
         state.pageStatus = "ready";
      })

   },


   reset() {
      set(state => {
         state.pageStatus = "closed"
         state.invoiceList = []
      })
   }


})))