import HttpRequest from "@/components/httpRequest";
import useAppInfo from "@/modules/app/use-app-info";
import useCompanyGroupListUnit from "@/modules/company-group/company-group-list-unit/use-company-group-list-unit";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const useCompanyListUnit = create(
  immer((set, get) => ({
    clientDataVersion: 0,
    companies: [],
    companiesFiltered: undefined,
    balanceColInfo: [0, 0, 0, 0, 0],
    pageStatus: "loading",
    searchTerm: "",
    currentOrder: [{ columnKey: "companyName", direction: "ASC" }],

    async initialize() {
      try {
        set((state) => {
          state.pageStatus = "loading";
          state.searchTerm = "";
          state.companiesFiltered = undefined;
        });

        await Promise.all([
          useCompanyGroupListUnit.getState().loadCompanyGroups(),
          get().loadCompanies(),
        ]);

        set((state) => {
          state.pageStatus = "ready";
        });
      } catch {
        set((state) => {
          state.pageStatus = errorId;
        });
      }
    },

    async loadCompanies() {
      const { clientDataVersion } = get();

      const { companyList, dbDataVersion, notModified } =
        await getCompanies(clientDataVersion);

      if (notModified) return;

      set((state) => {
        state.clientDataVersion = dbDataVersion;
        state.companies = companyList;
        state.customerOptions = prepareOptions(companyList, 1, "MÜŞTERİLER");
        state.supplierOptions = prepareOptions(companyList, 2, "TEDARİKÇİLER");

        state.balanceColInfo = prepareBalanceColInfo(companyList);
      });
    },

    setSearchTerm(searchTerm) {
      const searchTermLowerCase = searchTerm.toLocaleLowerCase("tr");

      set((state) => {
        if (!searchTerm) {
          state.companiesFiltered = undefined;
        } else if (
          state.companiesFiltered &&
          searchTerm.startsWith(state.searchTerm)
        ) {
          state.companiesFiltered = state.companiesFiltered.filter((c) =>
            c.companyNameLower.includes(searchTermLowerCase),
          );
        } else {
          state.companiesFiltered = state.companies.filter((c) =>
            c.companyNameLower.includes(searchTermLowerCase),
          );
        }

        state.searchTerm = searchTerm;
      });
    },

    changeOrder(newOrder) {
      set((state) => {
        if (newOrder.length === 0) {
          state.currentOrder[0]["direction"] =
            state.currentOrder[0]["direction"] === "ASC" ? "DESC" : "ASC";
        } else {
          state.currentOrder = newOrder;
        }
        const columnKey = state.currentOrder[0].columnKey;
        const asc = state.currentOrder[0]["direction"] === "ASC";

        let sortFunc;
        switch (columnKey) {
          case "companyName":
            sortFunc = asc
              ? (first, second) =>
                first["companyNameLower"].localeCompare(
                  second["companyNameLower"],
                )
              : (second, first) =>
                first["companyNameLower"].localeCompare(
                  second["companyNameLower"],
                );
            break;

          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
            sortFunc = makeSortFuncForBalanceCol(columnKey, asc);
            break;
          case "groups":
            sortFunc = makeSortFuncForGroupsCol(asc);
            break;
          default:
            sortFunc = makeSortFuncFuncForNumCol(columnKey, asc);
            break;
        }

        state.companies = state.companies.sort(sortFunc);
        if (state.companiesFiltered) {
          state.companiesFiltered = state.companiesFiltered.sort(sortFunc);
        }
      });
    },
  })),
);

function getCompanies(clientDataVersion) {
  const { appApiAddress, appToken } = useAppInfo.getState();

  return new HttpRequest(
    appApiAddress,
    `/metot/get-company-list?clientDataVersion=${clientDataVersion}`,
  )
    .addHeader("authorization", appToken)
    .get();
}

function prepareOptions(companies, primaryGroupId, primaryGroupName) {
  const primaryAndOthers = companies.reduce(reducer, [[], []]);

  return [
    { label: primaryGroupName, options: primaryAndOthers[0] },
    { label: "DİĞER KİŞİLER", options: primaryAndOthers[1] },
  ].filter((g) => g.options.length > 0);

  function reducer(acc, company) {
    const isPrimary = company.groups.includes(primaryGroupId);

    acc[isPrimary ? 0 : 1].push({
      companyId: company.companyId,
      companyName: company.companyName,
    });
    return acc;
  }
}

function prepareBalanceColInfo(companies) {
  return companies.reduce(
    (arr, row) => [
      1,
      arr[1] || row.balances[1],
      arr[2] || row.balances[2],
      arr[3] || row.balances[3],
      arr[4] || row.balances[4],
    ],
    [1, 0, 0, 0, 0],
  );
}

export default useCompanyListUnit;

function makeSortFuncForGroupsCol(asc) {
  return asc
    ? (first, second) =>
      first["groupsConcat"]?.localeCompare(second["groupsConcat"]) ||
      first["companyNameLower"].localeCompare(second["companyNameLower"])
    : (second, first) =>
      first["groupsConcat"]?.localeCompare(second["groupsConcat"]) ||
      second["companyNameLower"].localeCompare(first["companyNameLower"]);
}

function makeSortFuncFuncForNumCol(columnKey, asc) {
  return asc
    ? (first, second) =>
      first[columnKey] - second[columnKey] ||
      first["companyNameLower"].localeCompare(second["companyNameLower"])
    : (second, first) =>
      first[columnKey] - second[columnKey] ||
      second["companyNameLower"].localeCompare(first["companyNameLower"]);
}

function makeSortFuncForBalanceCol(columnKey, asc) {
  return asc
    ? (first, second) =>
      first["balances"][columnKey] - second["balances"][columnKey] ||
      first["companyNameLower"].localeCompare(second["companyNameLower"])
    : (second, first) =>
      first["balances"][columnKey] - second["balances"][columnKey] ||
      second["companyNameLower"].localeCompare(first["companyNameLower"]);
}
