import HttpRequest from "@/components/httpRequest";
import { invoiceModuleVars } from "@/modules/invoice/invoice-module-vars";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import useAppInfo from "../../app/use-app-info";

const RECORDS_PER_PAGE = 20;

const initialState = {
  vars: {},
  totalRecordCount: 0,
  invoiceList: [],
  pageStatus: "loading",
  allOver: false,
  invoiceTypeId: undefined,
  currentOrder: [{ columnKey: "lastEdit", direction: "DESC" }],
};
const useInvoiceListUnit = create(immer((set, get) =>
({
  ...initialState,

  initialize(invoiceTypeId) {
    set((state) => {
      state.invoiceTypeId = invoiceTypeId;
      state.vars = invoiceModuleVars.find(v => v.mainInvoiceTypeId === invoiceTypeId);
    });
    get().loadInvoiceList(0);
  },



  async loadInvoiceList(skip) {
    try {
      const { invoiceTypeId, currentOrder, vars } = get();

      if (skip > 0) {
        set((state) => {
          state.pageStatus = "appending";
        });
      }
      const params = {
        invoiceTypeIds: [invoiceTypeId, vars.returnInvoiceTypeId, vars.cancelTypeId],
        take: RECORDS_PER_PAGE,
        skip,
        term: 2024,
        searchTerm: undefined,
        sortKey: currentOrder[0]["sortKey"],
        desc: currentOrder[0]["direction"] === "DESC",
      };
      const { count, list } = await getInvoiceList(params);
      set((state) => {
        state.invoiceList =
          skip === 0 ? list : [...state.invoiceList, ...list];
        if (count !== null) state.totalRecordCount = count;
        if (list.length < RECORDS_PER_PAGE) {
          state.allOver = true;
          state.totalRecordCount = state.invoiceList.length;
        }
        state.pageStatus = "ready";
      });
    } catch (errorId) {
      set((state) => {
        state.pageStatus = errorId;
      });
    }
  },



  reset() {
    set((state) => ({ ...state, ...initialState }));
  },



  changeOrder(newOrder) {
    set((state) => {
      if (newOrder.length === 0) {
        state.currentOrder[0]["direction"] =
          state.currentOrder[0]["direction"] === "DESC" ? "ASC" : "DESC";
      } else {
        state.currentOrder = newOrder;
      }
      state.pageStatus = "appending";
      state.allOver = false;
    });
    get().loadInvoiceList(0);
  },
})),
);

export default useInvoiceListUnit;

export function getInvoiceList(params) {
  const { appApiAddress, appToken } = useAppInfo.getState();

  return new HttpRequest(appApiAddress, `/metot/get-invoice-list`)
    .addHeader("authorization", appToken)
    .postAsJson(params);
}
