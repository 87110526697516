import HttpRequest from "@/components/httpRequest";
import { formatDateToISO, formatMoney22 } from "@/components/utils";
import useAppInfo, { showErrorMessage, showSuccessMessage } from "@/modules/app/use-app-info";
import useBankAccountListUnit from "@/modules/bank-account/use-bank-account-list-unit";
import { cashMovementModuleVars } from "@/modules/cash-movement/cash-movement-module-vars";
import { CASH_MOVEMENT_TYPES } from "@/modules/cash-movement/cash-movement-types";
import useCompanyListUnit from "@/modules/company/company-list-unit/use-company-list-unit";
import useCurrencyListUnit from "@/modules/currency/use-currency-list-unit";
import useInvoiceUnit from "@/modules/invoice/invoice-unit/use-invoice-unit";
import { addPaymentToInvoice } from "@/modules/invoice/payment/use-payment-unit";
import useVaultAccountListUnit from "@/modules/vault-account/use-vault-account-list-unit";
import _isEmpty from "lodash/isEmpty";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
const REQUIRED_MESSAGE = "Zorunlu alan";

const findErrors = (m) => {
  const errors = {};
  const sourceAndTargetEqual = (m.sourceCurrencyId || m.targetCurrencyId || 1) === (m.targetCurrencyId || m.sourceCurrencyId || 1);
  if (!m.movementDate) errors.movementDate = REQUIRED_MESSAGE;
  switch (m.cashMovementTypeId) {
    case CASH_MOVEMENT_TYPES.OUTGOING_TRANSFER:
      required("companyId");
      required("sourceBankAccountId");
      required("sourceAmount");
      required("targetAmount");
      validateEffectiveTarget();
      break;
    case CASH_MOVEMENT_TYPES.INCOMING_TRANSFER:
      required("companyId");
      required("targetBankAccountId");
      required("sourceAmount");
      required("targetAmount");
      validateEffectiveSource();
      break;
    case CASH_MOVEMENT_TYPES.PAYMENT_BY_HAND:
      required("companyId");
      required("sourceVaultAccountId");
      required("sourceAmount");
      required("targetAmount");
      validateEffectiveTarget();
      break;
    case CASH_MOVEMENT_TYPES.RECEIPT_BY_HAND:
      required("companyId");
      required("targetVaultAccountId");
      required("sourceAmount");
      required("targetAmount");
      validateEffectiveSource();
      break;
    case CASH_MOVEMENT_TYPES.DEPOSIT:
      required("sourceVaultAccountId");
      required("targetBankAccountId");
      required("sourceAmount");
      required("targetAmount");
      break;
    case CASH_MOVEMENT_TYPES.WITHDRAW:
      required("sourceBankAccountId");
      required("targetVaultAccountId");
      required("sourceAmount");
      required("targetAmount");
      break;
    case CASH_MOVEMENT_TYPES.BANK_VIREMENT:
      required("sourceBankAccountId");
      required("targetBankAccountId");
      required("sourceAmount");
      required("targetAmount");
      break;
    case CASH_MOVEMENT_TYPES.VAULT_VIREMENT:
      required("sourceVaultAccountId");
      required("targetVaultAccountId");
      required("sourceAmount");
      required("targetAmount");
      break;
    case CASH_MOVEMENT_TYPES.BANK_INFLOW:
      required("targetBankAccountId");
      required("targetAmount");
      break;
    case CASH_MOVEMENT_TYPES.BANK_OUTFLOW:
      required("sourceBankAccountId");
      required("sourceAmount");
      break;
    case CASH_MOVEMENT_TYPES.VAULT_INFLOW:
      required("targetVaultAccountId");
      required("targetAmount");
      break;
    case CASH_MOVEMENT_TYPES.VAULT_OUTFLOW:
      required("sourceVaultAccountId");
      required("sourceAmount");
      break;
    default:
  }
  function required(fieldName) {
    if (!m[fieldName]) errors[fieldName] = REQUIRED_MESSAGE;
  }

  function validateEffectiveTarget() {
    if (sourceAndTargetEqual && m.maxEffectiveAmount > 0 && m.sourceAmount > m.maxEffectiveAmount) {
      errors["sourceAmount"] = maxMessage();
    }
    else if (!sourceAndTargetEqual && m.maxEffectiveAmount > 0 && m.targetAmount > m.maxEffectiveAmount) {
      errors["targetAmount"] = maxMessage();
    }
  }

  function validateEffectiveSource() {
    if (sourceAndTargetEqual && m.maxEffectiveAmount > 0 && m.targetAmount > m.maxEffectiveAmount) {
      errors["targetAmount"] = maxMessage();
    }
    else if (!sourceAndTargetEqual && m.maxEffectiveAmount > 0 && m.sourceAmount > m.maxEffectiveAmount) {
      errors["sourceAmount"] = maxMessage();
    }
  }
  function maxMessage() {
    return <>"<strong className="mx-0.5">{formatMoney22(m.maxEffectiveAmount)}</strong>" değerinden büyük olamaz</>;
  }
  return errors;


};

const initialState = {
  reloadKey: 0,
  pageStatus: "loading",
  editorMode: undefined,
  movement: {},
  errors: {},
  vars: {},
  currentTransaction: undefined,
  validationWasMade: false
};


const useCashMovementUnit = create(
  immer((set, get) => ({
    ...initialState,

    setEditorMode(editorMode) {
      set(state => {
        state.editorMode = editorMode;
      });
    },

    reload() {
      set((state) => {
        state.editorMode = false;
        state.reloadKey++;
      });
    },

    changeMovement(values) {
      set((state) => {
        state.movement = { ...state.movement, ...values };
      });

      const { validate, validationWasMade } = get();
      if (validationWasMade) validate();
    },

    validate() {
      const errors = findErrors(get().movement);
      set((state) => {
        state.errors = errors;
        state.validationWasMade = true;
      });
      return _isEmpty(errors);
    },

    async initialize(initialMovement, editorMode) {
      try {
        set(state => {
          state.vars = cashMovementModuleVars.find((v) => v.cashMovementTypeId === initialMovement.cashMovementTypeId);
          state.pageStatus = "loading";
          state.editorMode = editorMode;
        });

        await Promise.all([
          useCurrencyListUnit.getState().loadCurrencies(),
          useCompanyListUnit.getState().loadCompanies(),
          useBankAccountListUnit.getState().loadBankAccounts(),
          useVaultAccountListUnit.getState().loadVaultAccounts(),
          isNaN(initialMovement.cashMovementId) ? loadNew() : loadCashMovement()
        ]);

        set(state => {
          state.pageStatus = "ready";
        })

        async function loadCashMovement() {
          const movement = await fetchMovement(initialMovement.cashMovementId);
          set((state) => {
            state.movement = movement;
          });
        }

        function loadNew() {
          set((state) => {
            state.movement = {
              ...initialMovement,
              movementDate: initialMovement.movementDate || formatDateToISO(new Date()),
            };
            state.editorMode = editorMode ?? true;
          });
        }

      } catch (errorId) {
        set((state) => {
          state.pageStatus = errorId;
        });
      }

    },

    reset() {
      set((state) => ({ ...state, ...initialState }));
    },

    async save() {
      try {
        set((state) => {
          state.pageStatus = "running";
        });
        const payload = prepareCashMovementData(get().movement);
        if (payload.cashMovementId) await updateMovement(payload);
        else await createMovement(payload);

        showSuccessMessage("Kayıt işlemi tamamlandı");
        return true;
      } catch {
        showErrorMessage("Hata oluştu");
      } finally {
        set((state) => {
          state.pageStatus = "ready";
        });
      }
    },


    async deleteCashMovement() {
      try {
        set((state) => {
          state.pageStatus = "running";
        });
        await deleteCashMovement(get().movement.cashMovementId);
        showSuccessMessage("Silme işlemi tamamlandı");
        return true;
      } catch {
        showErrorMessage("Hata oluştu");
      } finally {
        set((state) => {
          state.pageStatus = "ready";
        });
      }


    },


    async addPaymentToInvoice(payment) {
      try {
        set((state) => {
          state.pageStatus = "running";
        });
        const invoiceId = useInvoiceUnit.getState().invoice.invoiceId;
        await addPaymentToInvoice(invoiceId, payment);
        showSuccessMessage("Kayıt işlemi tamamlandı");
        return true;
      } catch {
        showErrorMessage("Hata oluştu");
      } finally {
        set((state) => {
          state.pageStatus = "ready";
        });
      }

    },


  })),
);

export default useCashMovementUnit;

function fetchMovement(cashMovementId) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, `/metot/get-cash-movement/${cashMovementId}`)
    .addHeader("authorization", appToken)
    .get();
}

function createMovement(movement) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/create-cash-movement")
    .addHeader("authorization", appToken)
    .postAsJson(movement);
}

function updateMovement(movement) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, "/metot/update-cash-movement")
    .addHeader("authorization", appToken)
    .putAsJson(movement);
}

export function deleteCashMovement(movementId) {
  const { appApiAddress, appToken } = useAppInfo.getState();
  return new HttpRequest(appApiAddress, `/metot/delete-cash-movement/${movementId}`)
    .addHeader("authorization", appToken)
    .delete();
}

function prepareCashMovementData(movement) {
  if (isSingleType(movement.cashMovementTypeId)) {
    return movement;
  }

  return {
    ...movement,
    targetCurrencyId: movement.targetCurrencyId || movement.sourceCurrencyId,
    sourceCurrencyId: movement.sourceCurrencyId || movement.targetCurrencyId,
    exchangeRate: movement.exchangeRate || 1
  };
}

function isSingleType(movementType) {
  switch (movementType) {
    case CASH_MOVEMENT_TYPES.BANK_INFLOW:
    case CASH_MOVEMENT_TYPES.BANK_OUTFLOW:
    case CASH_MOVEMENT_TYPES.VAULT_INFLOW:
    case CASH_MOVEMENT_TYPES.VAULT_OUTFLOW:
      return true;
    default:
      return false;
  }
}

function isSourceToTarget(movementType) {
  switch (movementType) {
    case CASH_MOVEMENT_TYPES.OUTGOING_TRANSFER:
    case CASH_MOVEMENT_TYPES.PAYMENT_BY_HAND:
    case CASH_MOVEMENT_TYPES.DEPOSIT:
    case CASH_MOVEMENT_TYPES.WITHDRAW:
    case CASH_MOVEMENT_TYPES.BANK_VIREMENT:
    case CASH_MOVEMENT_TYPES.VAULT_VIREMENT:
      return true;
    default:
      return false;
  }
}







/*
(!!!) remove isSingleType, isSourceToTarget functions. use cash-movement-module-vars
*/