export default function MetotFaRegCircleDot({ className, size, style }) {
  const computedSize = size || "1em";
  return (
    <svg
      className={className}
      style={style}
      height={computedSize}
      width={computedSize}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
    </svg>
  );
}
