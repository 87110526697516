import { modules } from "@/modules/common/modules";
import { INVOICE_TYPES } from "@/modules/invoice/invoice-params";

export const invoiceModuleVars = Object.freeze([
  {
    mainInvoiceTypeId: modules.SELLING_INVOICE,
    cancelTypeId: INVOICE_TYPES.CANCELLED_SELLING_INVOICE,
    negOp: false,
    returnInvoiceTypeId: INVOICE_TYPES.EXPENSE_INVOICE_RETURN,
    counterTypeId: modules.EXPENSE_INVOICE,
    pluralName: "Satış Faturaları",
    recordName: "Satış Faturası",
    returnRecordName: "ALIŞ İADE",
    listPagePath: "satis-faturalari",
    returnPath: "alis-faturalari",
    paidLabel: "TAHSİL EDİLEN",
    beenPaidLabel: "TAHSİL EDİLDİ",
    paymentHeader: "TAHSİLATLAR",
    paymentHeaderSingular: "TAHSİLAT",
    paymentHeaderSingularLower: "Tahsilat",
    addPaymentButtonLabel: "Tahsilat Ekle",
    effectiveCurrencyPickerLabel: "TAHSİLAT PARA BİRİMİ",
    amountWillBePaidLabel: "TAHSİL EDİLECEK TUTAR",
    primaryCompanyGroup: "customers",
  },
  {
    mainInvoiceTypeId: modules.EXPENSE_INVOICE,
    cancelTypeId: INVOICE_TYPES.CANCELLED_EXPENSE_INVOICE,
    negOp: true,
    returnInvoiceTypeId: INVOICE_TYPES.SELLING_INVOICE_RETURN,
    counterTypeId: modules.SELLING_INVOICE,
    pluralName: "Alış Faturaları",
    recordName: "Alış Faturası",
    returnRecordName: "SATIŞ İADE",
    listPagePath: "alis-faturalari",
    returnPath: "satis-faturalari",
    paidLabel: "ÖDENEN",
    beenPaidLabel: "ÖDENDİ",
    paymentHeader: "ÖDEMELER",
    paymentHeaderSingular: "ÖDEME",
    paymentHeaderSingularLower: "Ödeme",
    addPaymentButtonLabel: "Ödeme Ekle",
    effectiveCurrencyPickerLabel: "ÖDEME PARA BİRİMİ",
    amountWillBePaidLabel: "ÖDENECEK TUTAR",
    primaryCompanyGroup: "suppliers",
  },
]);
