import { MAX_MONEY, roundFloat2, roundFloat6 } from "@/components/utils";
import useInvoiceUnit from "@/modules/invoice/invoice-unit/use-invoice-unit";
import { produce } from "immer";
import isEmpty from "lodash/isEmpty";
import _uniqueId from "lodash/uniqueId";
import { create } from "zustand";

const REQUIRED_MESSAGE = "Zorunlu alan";

const INITIAL_SUMMARY = {
  sumOfBasePrices: 0,
  sumOfVatAmounts: 0,
  grossTotal: 0,
  grandTotal: 0,
};


const useInvoiceDetailUnit = create((set, get) => ({
  summary: INITIAL_SUMMARY,
  rows: [],

  changeSummary(values) {
    set(
      produce((state) => {
        state.summary = calculateSums(state.rows, {
          ...state.summary,
          ...values,
        });
      }),
    );
  },

  changeAllRows(values) {
    set(
      produce((state) => {
        for (let index in state.rows) {
          const row = calculateRow({
            ...state.rows[index],
            ...values
          });
          if (row.errors) row.errors = getErrors(row);

          state.rows[index] = row;
        }
        state.summary = calculateSums(state.rows, state.summary);
      }),
    );
  },

  changeRow(index, values, calculationType) {
    set(
      produce((state) => {
        let row = { ...state.rows[index], ...values };
        switch (calculationType) {
          case 0:
            break;
          case 2:
            row = calculateRowAccordingToTheAmount(row);
            break;
          default:
            row = calculateRow(row);
            break;
        }

        if (row.errors) row.errors = getErrors(row);

        state.rows[index] = row;
        state.summary = calculateSums(state.rows, state.summary);
      }),
    );
  },

  addRow() {
    set(
      produce((state) => {
        state.rows.push({
          invoiceRowId: _uniqueId("-"),
        });
      }),
    );
  },

  deleteRow(index) {
    set(
      produce((state) => {
        state.rows.splice(index, 1);
        state.summary = calculateSums(state.rows, state.summary);
      }),
    );
  },

  validate() {
    let valid = true;
    const rows = get().rows.map((r) => {
      const errors = getErrors(r);
      valid &&= isEmpty(errors);
      return { ...r, errors };
    });
    set(
      produce((state) => {
        state.rows = rows;
      }),
    );
    return valid;
  },

  initialize(rows, summary) {
    set(produce((state) => {
      state.rows = rows?.map(normalizeRow) || [{ invoiceRowId: _uniqueId("-") }];
      state.summary = summary || INITIAL_SUMMARY;
    }));
  },


  addRowsAndRecalculate(rows, summary, options) {
    rows = rows?.map(row => calculateRow(normalizeRow(row)));

    set(produce(state => {
      rows = options?.append === true ? [...state.rows, ...rows] : rows;
      state.rows = rows;
      state.summary = calculateSums(rows, { ...state.summary, ...summary });
    }));

  },


  convertUnitPrices(oldCurrencyId, newCurrencyId) {
    const exchangeRates = useInvoiceUnit.getState().exchangeRates;
    const oldPrice =
      oldCurrencyId === 1
        ? 1
        : exchangeRates.find((c) => c.currencyId === oldCurrencyId)[
        "buyingPrice"
        ];
    const newPrice =
      newCurrencyId === 1
        ? 1
        : exchangeRates.find((c) => c.currencyId === newCurrencyId)[
        "buyingPrice"
        ];

    const conversionRate = oldPrice / newPrice;
    set(
      produce((state) => {
        for (let index in state.rows) {
          const row = state.rows[index];
          row.unitPrice = roundFloat6((row.unitPrice || 0) * conversionRate);
          state.rows[index] = calculateRow(row);
        }
        state.summary = calculateSums(state.rows, state.summary);
      }),
    );
  },

  reset() {
    set(produce(state => {
      state.summary = INITIAL_SUMMARY;
      state.rows = [];
    }));
  }
}));

function calculateRow(row) {
  const unitPrice = row.unitPrice || 0;
  const quantity = row.quantity || 0;
  const basePrice = roundFloat2(unitPrice * quantity);

  //İNDİRİM
  switch (row.discountType) {
    case 1:
      row.discountAmount = roundFloat2(basePrice * (row.discountPercent / 100));
      break;
    case 2:
      row.discountAmount = roundFloat2(Math.min(row.discountAmount, basePrice));
      row.discountPercent = (row.discountAmount / basePrice) * 100;
      break;
    default:
      row.discountPercent = undefined;
      row.discountAmount = undefined;
  }
  const basePriceReduced = roundFloat2(basePrice - (row.discountAmount || 0));

  //ÖTV
  switch (row.consumptionTaxType) {
    case 1:
      row.consumptionTaxAmount = roundFloat2(
        basePriceReduced * (row.consumptionTaxPercent / 100),
      );
      break;
    case 2:
      row.consumptionTaxPercent =
        basePriceReduced > 0
          ? (row.consumptionTaxAmount / basePriceReduced) * 100
          : 0;
      break;
    default:
      row.consumptionTaxPercent = undefined;
      row.consumptionTaxAmount = undefined;
  }

  //ÖİV
  if (row.communicationTaxType === 1) {
    const factor = row.communicationTaxPercent / 100 || 0;
    row.communicationTaxAmount = roundFloat2(basePriceReduced * factor);
  } else {
    row.communicationTaxPercent = undefined;
    row.communicationTaxAmount = undefined;
  }

  //ÖİV OF ÖTV
  if (row.communicationTaxType === 1 && row.consumptionTaxAmount > 0) {
    const factor = row.communicationTaxPercent / 100 || 0;
    row.communicationTaxAmountOfConsumptionTax = roundFloat2(
      row.consumptionTaxAmount * factor,
    );
  } else {
    row.communicationTaxAmountOfConsumptionTax = undefined;
  }

  //KONAKLAMA V.
  if (row.accommodationTaxType === 1) {
    const factor = row.accommodationTaxPercent / 100 || 0;
    row.accommodationTaxAmount = roundFloat2(basePriceReduced * factor);
  } else {
    row.accommodationTaxPercent = undefined;
    row.accommodationTaxAmount = undefined;
  }

  const vatFactor = row.vatPercent / 100;
  const withholdingFactor = row.withholdingPercent / 100;

  const vatAmount =
    row.vatPercent && basePriceReduced
      ? roundFloat2(basePriceReduced * vatFactor)
      : 0;
  const withholdingOfVatAmount =
    row.withholdingPercent > 0 ? roundFloat2(vatAmount * withholdingFactor) : 0;

  const vatAmountOfConsumptionTax =
    row.vatPercent && row.consumptionTaxAmount > 0
      ? roundFloat2(row.consumptionTaxAmount * vatFactor)
      : 0;

  const withholdingOfVatAmountOfConsumptionTax =
    row.withholdingPercent > 0
      ? roundFloat2(vatAmountOfConsumptionTax * withholdingFactor)
      : 0;

  const total = roundFloat2(
    basePriceReduced +
    (vatAmount - withholdingOfVatAmount) +
    (row.consumptionTaxAmount || 0) +
    (vatAmountOfConsumptionTax - withholdingOfVatAmountOfConsumptionTax) +
    (row.communicationTaxAmount || 0) +
    (row.communicationTaxAmountOfConsumptionTax || 0) +
    (row.accommodationTaxAmount || 0),
  );

  return {
    ...row,
    basePrice,
    vatAmount,
    vatAmountOfConsumptionTax,
    withholdingOfVatAmount,
    withholdingOfVatAmountOfConsumptionTax,
    total,
  };
}

function calculateRowAccordingToTheAmount(row) {
  const quantity = row.quantity || 1;
  const total = row.total;

  let vatPercent = row.vatPercent || 0;
  const witholdingRatio = row.withholdingPercent / 100 || 0;
  vatPercent -= vatPercent * witholdingRatio;
  const vatRatio = vatPercent / 100;

  const consumptionTaxRatio =
    row.consumptionTaxType > 0 ? (row.consumptionTaxPercent || 0) / 100 : 0;
  const consumptionTaxAmount =
    row.consumptionTaxType > 0 ? row.consumptionTaxAmount || 0 : 0;
  const communicationTaxRatio =
    row.communicationTaxType > 0 ? (row.communicationTaxPercent || 0) / 100 : 0;
  const accommodationTaxRatio =
    row.accommodationTaxType > 0 ? (row.accommodationTaxPercent || 0) / 100 : 0;

  let basePriceReduced;
  if (row.consumptionTaxType === 2) {
    basePriceReduced =
      ((total || 0) -
        consumptionTaxAmount * (1 + vatRatio + communicationTaxRatio)) /
      (1 + vatRatio + communicationTaxRatio + accommodationTaxRatio);
  } else {
    basePriceReduced =
      (total || 0) /
      (1 +
        vatRatio +
        consumptionTaxRatio +
        consumptionTaxRatio * vatRatio +
        communicationTaxRatio +
        consumptionTaxRatio * communicationTaxRatio +
        accommodationTaxRatio);
  }

  let basePrice = basePriceReduced;
  if (row.discountType === 1 && row.discountPercent > 0) {
    basePrice = basePriceReduced / (1 - row.discountPercent / 100);
  } else if (row.discountType === 2 && row.discountAmount > 0) {
    basePrice = basePriceReduced + row.discountAmount;
  }

  row.quantity = quantity;
  row.unitPrice = roundFloat6(basePrice / quantity);
  row = calculateRow(row);
  if (isNaN(total) === false && Math.abs(row.total - total) < 0.03) {
    row.total = total;
  }
  return row;
}

function getSubtotalDiscountPercent(rows, summary) {
  switch (summary.subtotalDiscountType) {
    case 1:
      return summary.subtotalDiscountPercent;
    case 2:
      const grossTotal = rows.reduce(
        (acc, currentRow) =>
          acc + (currentRow.basePrice - (currentRow.discountAmount || 0)),
        0,
      );
      const subtotalDiscountAmount = Math.min(
        summary.subtotalDiscountAmount,
        grossTotal,
      );
      return (subtotalDiscountAmount / grossTotal) * 100;
    default:
      return undefined;
  }
}

function calculateSums(rows, summary) {
  const { invoiceExchangeRate, effectiveExchangeRate } =
    useInvoiceUnit.getState().invoice;

  let sumOfBasePrices = 0,
    sumOfRowDiscounts = 0,
    sumOfVatAmounts = 0,
    sumOfConsumptionTaxAmounts = 0,
    sumOfCommunicationTaxAmounts = 0,
    sumOfAccommodationTaxAmounts = 0,
    subtotalDiscountPercent = summary.subtotalDiscountType
      ? getSubtotalDiscountPercent(rows, summary)
      : undefined,
    sumOfWithholdingAmounts = 0;

  const vatTable = {};
  const withholdingTable = {};

  const subDiscountFactor =
    subtotalDiscountPercent > 0 ? 1 - subtotalDiscountPercent / 100 : 1;
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    sumOfBasePrices += row.basePrice || 0;
    sumOfRowDiscounts += row.discountAmount || 0;

    let consumptionTaxAmount = row.consumptionTaxAmount || 0;
    if (row.consumptionTaxType === 1) {
      consumptionTaxAmount *= subDiscountFactor;
    }
    sumOfConsumptionTaxAmounts += consumptionTaxAmount;

    let communicationTaxAmount = row.communicationTaxAmount || 0;
    if (row.communicationTaxType === 1) {
      communicationTaxAmount *= subDiscountFactor;
    }
    sumOfCommunicationTaxAmounts += communicationTaxAmount;

    let communicationTaxAmountOfConsumptionTax =
      row.communicationTaxAmountOfConsumptionTax || 0;
    if (row.communicationTaxType === 1 && row.consumptionTaxType === 1) {
      communicationTaxAmountOfConsumptionTax *= subDiscountFactor;
    }
    sumOfCommunicationTaxAmounts += communicationTaxAmountOfConsumptionTax;

    let accommodationTaxAmount = row.accommodationTaxAmount || 0;
    if (row.accommodationTaxType === 1) {
      accommodationTaxAmount *= subDiscountFactor;
    }
    sumOfAccommodationTaxAmounts += accommodationTaxAmount;

    const vatAmount = roundFloat2((row.vatAmount || 0) * subDiscountFactor);
    vatTable[row.vatPercent] = (vatTable[row.vatPercent] || 0) + vatAmount;
    sumOfVatAmounts += vatAmount;
    if (row.withholdingPercent > 0) {
      const withholdingOfVatAmount = roundFloat2(
        row.withholdingOfVatAmount * subDiscountFactor,
      );
      withholdingTable[row.withholdingPercent] =
        (withholdingTable[row.withholdingPercent] || 0) +
        withholdingOfVatAmount;
      sumOfWithholdingAmounts += withholdingOfVatAmount;
    }

    if (row.vatAmountOfConsumptionTax > 0) {
      let vatAmountOfConsumptionTax = row.vatAmountOfConsumptionTax;
      if (row.consumptionTaxType === 1) {
        vatAmountOfConsumptionTax = roundFloat2(
          vatAmountOfConsumptionTax * subDiscountFactor,
        );
      }
      vatTable[row.vatPercent] =
        (vatTable[row.vatPercent] || 0) + vatAmountOfConsumptionTax;
      sumOfVatAmounts += vatAmountOfConsumptionTax;
      if (row.withholdingPercent > 0) {
        const withholdingOfVatAmountOfConsumptionTax =
          row.consumptionTaxType === 1
            ? roundFloat2(
              row.withholdingOfVatAmountOfConsumptionTax * subDiscountFactor,
            )
            : row.withholdingOfVatAmountOfConsumptionTax;

        withholdingTable[row.withholdingPercent] =
          (withholdingTable[row.withholdingPercent] || 0) +
          withholdingOfVatAmountOfConsumptionTax;
        sumOfWithholdingAmounts += withholdingOfVatAmountOfConsumptionTax;
      }
    }
  }

  sumOfBasePrices = roundFloat2(sumOfBasePrices);
  sumOfRowDiscounts = roundFloat2(sumOfRowDiscounts);

  sumOfConsumptionTaxAmounts = roundFloat2(sumOfConsumptionTaxAmounts);
  sumOfCommunicationTaxAmounts = roundFloat2(sumOfCommunicationTaxAmounts);
  sumOfAccommodationTaxAmounts = roundFloat2(sumOfAccommodationTaxAmounts);
  sumOfWithholdingAmounts = roundFloat2(sumOfWithholdingAmounts);

  let subtotalDiscountAmount;
  switch (summary.subtotalDiscountType) {
    case 1:
      subtotalDiscountAmount = roundFloat2(
        (sumOfBasePrices - sumOfRowDiscounts) *
        ((subtotalDiscountPercent || 0) / 100),
      );
      break;
    case 2:
      subtotalDiscountAmount =
        sumOfBasePrices - sumOfRowDiscounts >= summary.subtotalDiscountAmount
          ? summary.subtotalDiscountAmount
          : sumOfBasePrices - sumOfRowDiscounts;
      break;
    default:
      subtotalDiscountAmount = 0;
  }

  const grossTotal = roundFloat2(
    sumOfBasePrices - sumOfRowDiscounts - subtotalDiscountAmount,
  );

  if (summary.stoppagePercent) {
    summary.stoppageAmount = roundFloat2(
      (grossTotal * summary.stoppagePercent) / 100,
    );
  }

  const grandTotal = roundFloat2(
    grossTotal +
    (sumOfVatAmounts - sumOfWithholdingAmounts) +
    sumOfConsumptionTaxAmounts +
    sumOfCommunicationTaxAmounts +
    sumOfAccommodationTaxAmounts -
    (summary.stoppageAmount || 0),
  );

  return {
    ...summary,
    sumOfBasePrices,
    sumOfRowDiscounts,
    sumOfVatAmounts,
    sumOfConsumptionTaxAmounts,
    sumOfCommunicationTaxAmounts,
    sumOfAccommodationTaxAmounts,
    grossTotal,
    grandTotal,
    grandTotalTry: roundFloat2(grandTotal * (invoiceExchangeRate || 1)),
    effectiveTotal: roundFloat2(grandTotal * (effectiveExchangeRate || 1)),
    subtotalDiscountPercent,
    subtotalDiscountAmount,
    vatTable,
    withholdingTable,
    sumOfWithholdingAmounts,
  };
}

function getErrors(row) {
  const errors = {};
  if (!row.productId) errors.productId = REQUIRED_MESSAGE;
  if (row.quantity > 0 !== true) errors.quantity = REQUIRED_MESSAGE;
  if (row.unitPrice > 0 !== true) errors.unitPrice = REQUIRED_MESSAGE;
  else if (row.basePrice > 0 !== true) errors.basePrice = "Matrah sıfır olamaz";
  if (row.total > MAX_MONEY) errors.total = "Geçersiz değer";

  return errors;
}

function normalizeRow(row) {
  return {
    ...row,
    description: row.description || undefined,
    units: JSON.parse(row.units),
    discountPercent: row.discountPercent || undefined,
    discountAmount: row.discountAmount || undefined,
    consumptionTaxPercent: row.consumptionTaxPercent || undefined,
    consumptionTaxAmount: row.consumptionTaxAmount || undefined,
    communicationTaxPercent: row.communicationTaxPercent || undefined,
    communicationTaxAmount: row.communicationTaxAmount || undefined,
  };
}

export default useInvoiceDetailUnit;

/*
(!!!) Validate if subtotaldiscountamount bigger than subtotal
*/
