import { CASH_MOVEMENT_TYPES } from "@/modules/cash-movement/cash-movement-types";

export const cashMovementModuleVars = Object.freeze([
   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.OUTGOING_TRANSFER,
      cashMovementTypeName: "Giden Havale",
      effectiveAmountPropName: "targetAmount",
      paymentOrReceipt: "ödemesidir",
      invoiceLinkPath: "alis-faturalari",
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.INCOMING_TRANSFER,
      cashMovementTypeName: "Gelen Havale",
      effectiveAmountPropName: "sourceAmount",
      paymentOrReceipt: "tahsilatıdır",
      invoiceLinkPath: "satis-faturalari",
   },
   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.PAYMENT_BY_HAND,
      cashMovementTypeName: "Elden Ödeme",
      effectiveAmountPropName: "targetAmount",
      paymentOrReceipt: "ödemesidir",
      invoiceLinkPath: "alis-faturalari",
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.RECEIPT_BY_HAND,
      cashMovementTypeName: "Elden Tahsilat",
      effectiveAmountPropName: "sourceAmount",
      paymentOrReceipt: "tahsilatıdır",
      invoiceLinkPath: "satis-faturalari",
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.DEPOSIT,
      cashMovementTypeName: "Hesaba Para Yatırma"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.WITHDRAW,
      cashMovementTypeName: "Hesaptan Para Çekme"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.BANK_VIREMENT,
      cashMovementTypeName: "Banka Virman"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.VAULT_VIREMENT,
      cashMovementTypeName: "Kasa Virman"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.BANK_OUTFLOW,
      cashMovementTypeName: "Hesaba Para Girişi"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.BANK_INFLOW,
      cashMovementTypeName: "Hesaptan Para Çıkışı"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.VAULT_OUTFLOW,
      cashMovementTypeName: "Kasaya Para Girişi"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.VAULT_INFLOW,
      cashMovementTypeName: "Kasadan Para Çıkışı"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.CHECK_RECEIPT_BY_HAND,
      cashMovementTypeName: "Elden Çek Tahsilatı"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.CHECK_RECEIPT_FROM_EXCHANGE,
      cashMovementTypeName: "Takastan Çek Tahsilatı"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.CHECK_RECEIPT_FROM_ASSURANCE,
      cashMovementTypeName: "Teminattan Çek Tahsilatı"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.BILL_RECEIPT_BY_HAND,
      cashMovementTypeName: "Elden Senet Tahsilatı"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.BILL_RECEIPT_FROM_EXCHANGE,
      cashMovementTypeName: "Takastan Senet Tahsilatı"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.BILL_RECEIPT_FROM_ASSURANCE,
      cashMovementTypeName: "Teminattan Senet Tahsilatı"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.CHECK_PAYMENT_BY_HAND,
      cashMovementTypeName: "Elden Çek Ödemesi"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.CHECK_PAYMENT_BY_BANK,
      cashMovementTypeName: "Bankadan Çek Ödemesi"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.BILL_PAYMENT_BY_HAND,
      cashMovementTypeName: "Elden Senet Ödemesi"
   },

   {
      cashMovementTypeId: CASH_MOVEMENT_TYPES.BILL_PAYMENT_BY_BANK,
      cashMovementTypeName: "Bankadan Senet Ödemesi"
   },

]);
