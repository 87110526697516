import HttpRequest from "@/components/httpRequest";
import useAppInfo from "@/modules/app/use-app-info";
import { getGroupArray } from "@/modules/company-group/company-group-list-unit/use-company-group-list-unit";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";


export const useProductGroupListUnit = create(
  immer((set, get) => ({
    clientDataVersion: 0,
    productGroups: [],
    async loadProductGroups() {
      const { clientDataVersion } = get();
      const { productGroupList, dbDataVersion, notModified } =
        await getProductGroups(clientDataVersion);

      if (!notModified) {
        const productGroupsIndexed = getGroupArray(productGroupList);
        set((state) => {
          state.productGroups = productGroupList;
          state.clientDataVersion = dbDataVersion;
          state.productGroupsIndexed = productGroupsIndexed;
        });
      }
    },
  })),
);

function getProductGroups(clientDataVersion) {
  const { appApiAddress, appToken } = useAppInfo.getState();

  return new HttpRequest(
    appApiAddress,
    `/metot/get-product-group-list?clientDataVersion=${clientDataVersion}`,
  )
    .addHeader("authorization", appToken)
    .get();
}
